export default {
	data() {
		return {
			getUsers: {
				userType: Number,
				uuid: null,
				name: null,
				roleName: null,
				AllRole: [],
				roleUuid: Number,
				page: 1,
				size: 10
			},
			tableData: [],
			operateList: [],
			total: 0
		}
	},
	created() {

	},
	mounted() {
		this.getConditionFromCache();

		this.getUsersList();
		this.getAllRole();
	},
	destroyed() {
		sessionStorage.removeItem("detail");
	},
	methods: {
		getConditionFromCache() {
			if (sessionStorage.getItem('detail')) {
				this.getUsers.page = Number(sessionStorage.getItem("currPage")) > 0 ? Number(sessionStorage.getItem(
					"currPage")) : 1;
				this.getUsers.size = Number(sessionStorage.getItem("size")) > 0 ? Number(sessionStorage.getItem(
					"size")) : 10;

				// account,deviceType,startCreateTime,endCreateTime
				let searchConditionStr = sessionStorage.getItem("serCondition");
				let searchCondition = JSON.parse(searchConditionStr);
				this.getUsers.name = searchCondition.name;
				this.getUsers.AllRole.value = searchCondition.roleVal;
			} else {
				this.getUsers.page = 1;
				this.getUsers.size = 10;

				sessionStorage.removeItem("currPage");
				sessionStorage.removeItem("size");
			}
		},
		//查询
		getUsersListInquire() {
			this.getUsers.page = 1;

			let searchCondition = {
				account: this.getUsers.account,
				roleVal: this.getUsers.AllRole.value
			};
			sessionStorage.setItem("serCondition", JSON.stringify(searchCondition));
			this.getUsersList();
		},
		//添加用户
		addUser() {
			this.$router.push({
				path: 'user-add'
			});
		},
		//编辑用户跳转
		redactButton(row) {
			this.$router.push({
				path: 'patient-edit',
				query: {
					usersUedact: row,
				}
			});
		},
		//select搜索角色列表
		getAllRole() {
			this.$axios
				.get("/api/web/role/findAll")
				.then(response => {
					this.getUsers.AllRole = response.data.data
					var vm = this;
					if (this.getUsers.AllRole != null) {
						this.getUsers.AllRole.forEach(function(item1) {
							vm.$set(item1, 'value', null);
						})
					}
				})
				.catch(e => {
					console.log(e);
					/* this.$message({
						message: "请求失败",
						type: "error"
					}); */
				});
		},
		//禁用用户
		doForbidden(uuid) {
			this.$confirm("您确定禁用吗?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				lockScroll: false,
				type: "warning"
			}).then(() => {
				this.$axios.get('/api/web/user/del/' + uuid).then(res => {
					if (res.data.code === 200) {
						this.getUsersList();
					}
					this.$message.success({
						message: res.data.message,
					});
				}).catch(error => {
					console.log(error);
					//this.$message.error("请求失败");
				});
			}).catch(() => {});
		},
		//启用用户
		dostartUsing(uuid) {
			this.$confirm("您确定启用吗?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				lockScroll: false,
				type: "warning"
			}).then(() => {
				this.$axios.get('/api/web/user/status/' + uuid + "/" + 1).then(res => {
					if (res.data.code === 200) {
						this.getUsersList();
					}
					this.$message.success({
						message: res.data.message,
					});
				}).catch(error => {
					console.log(error);
					//this.$message.error("请求失败");
				});
			}).catch(() => {});
		},
		// 监听 pagesize 改变的事件
		handleSizeChange(newSize) {
			this.getUsers.size = newSize;
			this.getUsersList();

			sessionStorage.setItem('size', newSize);
			sessionStorage.setItem('currPage', this.getUsers.page);
		},
		//监听 页码值 改变的事件
		handleCurrentChange(newPage) {
			this.getUsers.page = newPage;
			this.getUsersList();

			sessionStorage.setItem('currPage', newPage);
			sessionStorage.setItem('size', this.getUsers.size);
		},
		/*获取用户列表数据*/
		getUsersList() {
			this.tableData.splice(0, this.tableData.length); //清空数组
			this.getUsers.userType = sessionStorage.getItem('userType');
			this.getUsers.roleUuid = this.getUsers.AllRole.value;
			this.$axios({
				method: "post",
				url: "/api/web/user/pageUser", // 接口地址
				data: this.getUsers
			}).then(res => {
				if (res.data.code === 200) {
					this.tableData = res.data.data;
					this.total = res.data.pageData.totalElements;

					this.$refs.pagination.internalCurrentPage = this.getUsers.page;
				} else {
					this.$message({
						message: "操作失败",
						type: "error",
					});
				}
			}).catch(error => {
				console.log(error);
				//this.$message.error("请求失败");
			});
		},
	}
}
